import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import ImageUploadComponent from './ImageUploadComponent';
import '../App.css';


import UploadPic from '../photos/upload.png';
import { Print, SearchOff } from '@mui/icons-material';

interface TopComponentProps {
  onSearch: (query: string) => void;
  onButtonClick: () => void;
  onBackClick: () => void;
  onUploadClick: () => void;
  clearSearchQuery?: boolean;
}

const TopComponent : React.FC<TopComponentProps> = ({ onSearch, onButtonClick, onBackClick, onUploadClick, clearSearchQuery }) => {
 
  

  return (
    <div className="top-component">
      {/* <ImageUploadComponent></ImageUploadComponent> */}
      <SearchBar onSearch={onSearch} clearSearchQuery={clearSearchQuery}/>
      <button onClick={() => {onBackClick()}} className='print-button' id="back-arrow-button"><SearchOff></SearchOff></button>
      {/* <button onClick={onUploadClick} className='print-button' id='upload-spines'>
        <img src={UploadPic} id="upload-pic">
        
        </img> 
        </button> */}
      <button onClick={onButtonClick} className='print-button'>
          <Print />
      </button>
      
    </div>
  );
};

export default TopComponent;