import React, {useState, useEffect} from 'react';
import '../App.css';

interface SearchBarProps {
    onSearch: (query: string) => void;
    clearSearchQuery?: boolean; 
  }

const SearchBar : React.FC<SearchBarProps>= ({onSearch, clearSearchQuery}) => {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  const handleInputChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  
  useEffect(() => {
    setSearchQuery('');
    setDebouncedSearchQuery('');
  }, [clearSearchQuery]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 200); // 200 milliseconds

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  useEffect(() => {
    onSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery, onSearch]);

  // const handleSearchChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  return (
    <div className="search-bar">
    <input type="text" placeholder="Search all games" value={searchQuery} onChange={handleInputChange} />
  </div>
  );
};

export default SearchBar;