import React from "react";
import {Page, View, Text, Image, Document, StyleSheet, PDFViewer, PDFDownloadLink} from '@react-pdf/renderer';
import {isMobile, isBrowser} from 'react-device-detect';

import '../App.css';



const PDFFile = (imagesBase64 : any [], quantities?: number[], settings? : Map<string, string>) => {
    const imageSrc = '../photos/TOTK.png'; 
    const test = 'https://drive.google.com/uc?id=1-e8p0ZRHJ-m2U1WCycrTsIhCTwQjhHm1'

    const margin = settings?.get("margin") ?? '0.0';
    const marginTop = settings?.get("marginTop") ?? '0.0';
    const marginLeft = settings?.get("marginLeft") ?? '0.0';
    const marginRight = settings?.get("marginRight") ?? '0.0';

    const customPageWidth = settings?.get("pageWidth") ?? '8.5';
    const customPageHeight = settings?.get("pageHeight") ?? '11.0';
    
    const styles = StyleSheet.create({
      page: {
        backgroundColor: "white",
        color: "white",
        scale: '100%',
      },
      viewer: {
        height: '100%',
        width: '100%',
      },
      image: {
          width: 0.41 * 72,
          height: 6.35 * 72,
          display: 'flex',
          alignContent: 'flex-start',
          marginRight: parseFloat(margin) * 72,
          marginTop: parseFloat(marginTop) * 72,
      },
      container: {
        marginLeft: parseFloat(marginLeft) * 72,
        marginRight: (parseFloat(marginRight) * 72) - (parseFloat(margin) * 72) ?? 0,
        flexDirection: 'row',
        flexWrap: "wrap", 
        justifyContent: 'flex-start',
      },
    });
    

   
    


    const renderImages = () => {
      const imageComponents = [];
  
      // Iterate over the imagesBase64 and quantities arrays simultaneously
      for (let i = 0; i < imagesBase64.length; i++) {
        const imageBase64 = imagesBase64[i];
        const quantity = quantities?.at(i) ?? 1;
  
        // Render each image the number of times specified in quantities
        for (let j = 0; j < quantity; j++) {
          imageComponents.push(
              <Image style={styles.image} src={imageBase64.src} />
          );
        }
      }
  
      return imageComponents;
    };


    return (
    <div id="PDF">
        {/* <iframe style={styles.iframe} src="https://drive.google.com/embeddedfolderview?id=1km18qrL_ARYE-3qKUIT81FoMEyvI-8vf#grid">
        </iframe> */} 
        {(isMobile) ?
            <PDFDownloadLink
              document={<Document title="test"/>}
              fileName="YOUR_FILE_NAME"
            >
              {({ loading }) =>
                loading ? "Loading...": "Loaded"
              }
            </PDFDownloadLink> : <div></div>
        }
    
      <PDFViewer style={styles.viewer}>
        <Document title="Spines Print">
          <Page size={[(parseFloat(customPageWidth) > 1 ? parseFloat(customPageWidth) : 1) * 72, 
            (parseFloat(customPageHeight) > 1 ? parseFloat(customPageHeight) : 1) * 72]}  orientation="landscape" >
              
            <View style={styles.container}>
              {renderImages()}
             {/* {imagesBase64.map((imageBase64, index) => (<Image key={index} style={styles.image} src={imageBase64['src']} />))} */}
            </View>
          </Page>
        </Document>
      </PDFViewer>
      </div>
      
    );
  };

export default PDFFile;