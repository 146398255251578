import React, { useState, useEffect } from 'react';
import '../App.css';

const ImageUploadComponent : React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    if(showUpload) {
    const uploadContainer = document.getElementById("uploadContainer");
    if(uploadContainer) {
      uploadContainer.style.opacity = '1.0';
      uploadContainer.style.pointerEvents = 'all';
    }   
  } else {
    const uploadContainer = document.getElementById("uploadContainer");
    if(uploadContainer) {
      uploadContainer.style.opacity = '0.0';
      uploadContainer.style.pointerEvents = 'none';
    }   
  }

  }, [showUpload]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    // Send formData to your backend using fetch or axios
    // Example using fetch:
    fetch('/upload', {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error('Error uploading images:', error);
    });
    setShowUpload(false);
    const uploadButton = document.getElementById("showUploadButton");
    if(uploadButton) {
      uploadButton.style.opacity = '1.0';
      uploadButton.style.pointerEvents = 'all';
    }
    setSelectedFiles([]);   
  };

  function handleShowUploadClick() {
    setShowUpload(true);
    const uploadButton = document.getElementById("showUploadButton");
    if(uploadButton) {
      uploadButton.style.opacity = '0.0';
      uploadButton.style.pointerEvents = 'none';
    }   
  }

  return (
    <div id="uploadDiv">
    <button onClick={() => handleShowUploadClick()} id="showUploadButton">Upload Spine(s)</button>
    <div id="uploadContainer">
      <input type="file" accept="image/*" multiple onChange={handleFileChange} />
      <button onClick={handleUpload}>Confirm</button>
      {selectedFiles.map((file, index) => (
        <div key={index}>{file.name}</div>
      ))}
      </div>
    </div>
  );
};

export default ImageUploadComponent;