// ExpandableTextInputGroup.tsx

import React, { useState, useRef, useEffect } from 'react';
import StyledInput from './StyledInput';
import { Tune, Close } from '@mui/icons-material';
import '../App.css';

interface ExpandableTextInputGroupProps {
    handlePageHeightChange:  (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleMarginRightChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlePageWidthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleMarginLeftChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleMarginChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleMarginTopChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    margin: string;
    marginTop: string;
    marginLeft: string;
    marginRight: string;
    pageHeight: string;
    pageWidth: string;
  }

const ExpandableTextInputGroup: React.FC<ExpandableTextInputGroupProps> = ({margin, marginTop, marginLeft, marginRight, pageHeight, pageWidth, handlePageHeightChange, handleMarginRightChange, handlePageWidthChange, handleMarginLeftChange, handleMarginChange, handleMarginTopChange}) => {
  const [expanded, setExpanded] = useState(false);
  const [changedLast, setChangedLast] = useState(false);
  const [inputValues, setInputValues] = useState([
    { title: 'Spine Spacing', value: margin, onChange: handleMarginChange},
    { title: 'Page Width', value: pageHeight, onChange: handlePageHeightChange },
    { title: 'Page Height', value: pageWidth, onChange: handlePageWidthChange },
    { title: 'Margin Top', value: marginTop, onChange: handleMarginTopChange },
    { title: 'Margin Left', value: marginLeft, onChange: handleMarginLeftChange },
    { title: 'Margin Right', value: marginRight, onChange: handleMarginRightChange },
  ]);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleExpandClick = () => {
    if(!changedLast) {
      setExpanded(!expanded);
      setChangedLast(false);
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index].value = value;
    setInputValues(newInputValues);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setExpanded(false);
        setChangedLast(true);
        setTimeout(() => setChangedLast(false), 100);
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <div className="expandable-text-input-group">
      <button className="expand-button" onClick={(e) => {handleExpandClick();}}>
        { expanded ? <Close /> : <Tune /> }
      </button>
      {expanded && (
         <div ref={tooltipRef} className={`tooltip ${expanded ? 'active' : ''}`}>
          <div className="tooltip-content">
            {inputValues.map((input, index) => (
              <div key={index} className="input-field">
                <label>{input.title}</label>
                <StyledInput
                  value={input.value}
                  onChange={input.onChange}
                  handleInputChange={handleInputChange}
                  index={index}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandableTextInputGroup;
