
import ImageUploadComponent from "./components/ImageUploadComponent";







export default function UploadPage() {




    return (
        <div>
            <ImageUploadComponent/>
        </div>
    )


}