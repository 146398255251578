import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PDFFile from './components/PDFFile';
import './App.css';
import TopComponentPDF from './components/TopComponentPDF';
import {settings} from './App'
import ExpandableTextInputGroup from './components/ExpandableTextInputGroup ';



export default function PdfPage() {
    const {state} = useLocation();
    const {selected, idArr, quantities, images, loadedImages} = state;
    const [pdfDisplay, setPdfDisplay] = useState(PDFFile([]));
    const [margin, setMargin] = useState(settings.get('margin') ?? "0.1");
    const [marginTop, setMarginTop] = useState(settings.get('marginTop') ?? "0.5");
    const [marginLeft, setMarginLeft] = useState(settings.get('marginLeft') ?? "0.5");
    const [marginRight, setMarginRight] = useState(settings.get('marginRight') ?? "0.5");
    const [pageWidth, setPageWidth] = useState(settings.get('pageWidth') ?? "8.5");
    const [pageHeight, setPageHeight] = useState(settings.get('pageHeight') ?? "11.0");
    
    const redXcircle = require('./photos/redXCircle.png');
    const circleWithBlueCheckmark = require('./photos/circleWithCheckmark.png');
    const navigate = useNavigate();
    
    useEffect(() => {
        setPdfDisplay(PDFFile(selected, quantities));
    }, []);

    function shareableLink(id : string) {
        const urlPre = 'https://drive.google.com/uc?id=';
        return urlPre + id;
    }

    function thumbnailLink(id : string) {
      const urlPre = 'https://drive.google.com/thumbnail?id=';
      return urlPre + id + '&sz=s4000';
    }

    function mouseEnter (id : string) {
        const cir = document.getElementById(id + 'X');
        if(cir) {
          cir.style.opacity = '1.0';
        }
      }
    
      function mouseLeave (id : string) {
        const cir = document.getElementById(id + 'X');
        if(cir) {
          cir.style.opacity = '0.0';
        }
      }
    
      function showBlueCheck (id : string) {
        const cir = document.getElementById(id + 'Y');
        if(cir) {
          cir.style.opacity = '1.0';
        }
      }
    
      function hideBlueCheck (id : string) {
        const cir = document.getElementById(id + 'Y');
        if(cir) {
          cir.style.opacity = '0.0';
        }
      }

      const imageClick = (id : string) => {
        const delIndex = idArr.indexOf(id);

        if(delIndex > -1) {
            selected.splice(delIndex, 1);
            idArr.splice(delIndex, 1);
            quantities.splice(delIndex, 1);
            setPdfDisplay(PDFFile(selected, quantities, settings));
        }
    }
        function switchPage () {
            navigate('/', { state: { selected: selected, idArr: idArr, quantities: quantities, images: state['images'], loaded: state['loaded']} });
        }
        
        useEffect(() => {
          if(margin != '0.') {
            setSettings();
            setPdfDisplay(PDFFile(selected, quantities, settings));
          }
      }, [margin]);

      useEffect(() => {
        if(marginTop != '0.') {
          setSettings();
          setPdfDisplay(PDFFile(selected, quantities, settings));
        }
    }, [marginTop]);

    useEffect(() => {
      if(marginLeft != '0.') {
        setSettings();
        setPdfDisplay(PDFFile(selected, quantities, settings));
      }
    }, [marginLeft]);

    useEffect(() => {
      if(marginRight != '0.') {
        setSettings();
        setPdfDisplay(PDFFile(selected, quantities, settings));
      }
  }, [marginRight]);
  
  useEffect(() => {
    if(pageHeight.length > 0 && pageHeight.at(pageHeight.length - 1) != '.') {
      setSettings();
      setPdfDisplay(PDFFile(selected, quantities, settings));
    }
}, [pageHeight]);

useEffect(() => {
  if(pageWidth.length > 0 && pageWidth.at(pageWidth.length - 1) != '.') {
    setSettings();
    setPdfDisplay(PDFFile(selected, quantities, settings));
  }
}, [pageWidth]);


    function setSettings() {
        settings.set('margin', margin);
        settings.set('marginTop', marginTop);
        settings.set('marginLeft', marginLeft);
        settings.set('marginRight', marginRight);
        settings.set('pageWidth', pageWidth);
        settings.set('pageHeight', pageHeight);
    }    
      

      function handleMarginChange (e: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
    //     const countDecimals = (value: string) => {
    //     const match = value.match(/\.(\d+)/);
    //     return match ? match[1].length : 0;
    //   };
    //   const decimalCount = countDecimals(sanitizedValue);

    // // Allow only one decimal point
          if (1 <= 1) {
            setMargin(sanitizedValue);
          }
      }

      function handleMarginTopChange (e: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
      //   const countDecimals = (value: string) => {
      //   const match = value.match(/\.(\d+)/);
      //   return match ? match[1].length : 0;
      // };
      // const decimalCount = countDecimals(sanitizedValue);

    // Allow only one decimal point
          if (1 <= 1) {
            setMarginTop(sanitizedValue);
          }
      }

      function handleMarginLeftChange (e: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
        setMarginLeft(sanitizedValue);
      }

      function handleMarginRightChange (e: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
        setMarginRight(sanitizedValue);
      }

      const handlePageWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
        setPageWidth(sanitizedValue);
      }

      const handlePageHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
        setPageHeight(sanitizedValue);
      }

      function handleQuantityChange (e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const inputValue = e.target.value;
        let sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
        if(sanitizedValue == "") {
          sanitizedValue = '0';
        }
        quantities[index] = parseInt(sanitizedValue) < 100 ? Math.floor(parseInt(sanitizedValue)) : '99';
        setPdfDisplay(PDFFile(selected, quantities, settings));
      }
        
   

  return (
    <>
    <div className="page-container">
      <div className='left-side-container'>
      <TopComponentPDF onButtonClick={() => {switchPage()}} handlePageHeightChange={handlePageHeightChange} handleMarginRightChange={handleMarginRightChange} handlePageWidthChange={handlePageWidthChange}
      handleMarginLeftChange={handleMarginLeftChange} handleMarginChange={handleMarginChange} handleMarginTopChange={handleMarginTopChange} margin={margin} marginTop={marginTop} marginLeft={marginLeft} marginRight={marginRight} pageHeight={pageHeight} pageWidth={pageWidth} />
      <div className="image-panel">
       {(typeof idArr === 'undefined') 
      ? (<p> Loading </p>) 
      : (idArr.map((image : string, index : number) => ( 
        <div className='imageContainerPdfPage'>
          <img id={image} key={image} width={0.41 * 110} height={6.35 * 100} className="spineImagePDFPage" referrerPolicy="no-referrer" 
            src={(thumbnailLink(image))}
            onMouseEnter={()=> mouseEnter(image)} 
            onMouseLeave={()=> mouseLeave(image)}
            onClick={() => imageClick(image)}
            >  
          </img>
          <img className={'redXCircle'} id={image + "X"} key={image + "X"} src={redXcircle} width={30} height={30} onMouseEnter={()=> mouseEnter(image)} 
            onMouseLeave={()=> mouseLeave(image)} onClick={() => imageClick(image)}></img>
            <div className='quantity-input-container'>
                <input
                type="text"
                value={Math.floor(quantities[index])}
                onChange={(e) => {handleQuantityChange(e, index)}}
                className="quantity-input"
              />
            </div>
        </div>
      )))} 
      </div>
      
     {/* <div className='options-panel'>
     <div className='row'>

     <div className='textInputOption'> 
      <div id="optionsHeader">Margin Top</div>
      <div className='input-container'>
        <input
        type="text"
        value={marginTop}
        onChange={(e) => handleMarginTopChange(e)}
        className="text-input" // This class name is used to apply the CSS styles
      />
      <span className="input-placeholder">(inches)</span>
      </div>
      </div>

     <div className='textInputOption'> 
        <div id="optionsHeader">Spine Spacing</div>
        <div className='input-container'>
        <input
        type="text"
        value={margin}
        onChange={(e) => handleMarginChange(e)}
        className="text-input" // This class name is used to apply the CSS styles
      />
      <span className="input-placeholder">(inches)</span>
      </div>
      </div>

      

      </div>
      <br></br>
      <div className='row'>
     <div className='textInputOption'> 
        <div id="optionsHeader">Margin Left</div>
        <div className='input-container'>
        <input
        type="text"
        value={marginLeft}
        onChange={(e) => handleMarginLeftChange(e)}
        className="text-input" 
      />
      <span className="input-placeholder">(inches)</span>
      </div>
      </div>

      
     <div className='textInputOption'> 
        <div id="optionsHeader">Page Width</div>
        <div className='input-container'>
        <input
        type="text"
        value={pageWidth}
        onChange={(e) => handlePageWidthChange(e)}
        className="text-input" 
      />
      <span className="input-placeholder">(inches)</span>
      </div>
      </div>

      </div>
      <br></br>
      <div className='row'>


      <div className='textInputOption'> 
      <div id="optionsHeader">Margin Right</div>
      <div className='input-container'>
        <input
        type="text"
        value={marginRight}
        onChange={(e) => handleMarginRightChange(e)}
        className="text-input"
      />
      <span className="input-placeholder">(inches)</span>
      </div>
      </div>


      <div className='textInputOption'> 
      <div id="optionsHeader">Page Height</div>
      <div className='input-container'>
        <input
        type="text"
        value={pageHeight}
        onChange={(e) => handlePageHeightChange(e)}
        className="text-input"
      />
      <span className="input-placeholder">(inches)</span>
      </div>
      </div>
      </div>
     </div>*/}
     </div> 
      <div className="pdf-viewer">
        {/* <button onClick={() => {switchPage()}}>Next Page</button> */}
        {pdfDisplay}
        </div>
      </div>
      </>
  );
}
