// App.tsx

import React, { useState } from 'react';
import '../App.css';

interface StyledInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputChange: (index: number, value: string) => void;
    index: number;
  }
  
  const StyledInput: React.FC<StyledInputProps> = ({ value, onChange, handleInputChange, index }) => {
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
      handleInputChange(index, sanitizedValue);

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
  
      // Set a new typing timeout
      const newTypingTimeout = setTimeout(() => {
        onChange(event);
      }, 300); // Adjust the debounce delay as needed (e.g., 500 milliseconds)
  
      setTypingTimeout(newTypingTimeout);
    };
  
    return (
      <div className="styled-input-container">
      <input
        type="text"
        className="styled-input"
        value={value}
        onChange={(e) => {handleChange(e)}}
      />
      <span className="input-unit">(in)</span>
    </div>
    );
  };
  
  export default StyledInput;
