import React from 'react';
import { KeyboardReturnTwoTone  } from '@mui/icons-material';
import ExpandableTextInputGroup from './ExpandableTextInputGroup ';
import '../App.css';

interface TopComponentPropsPDF {
  onButtonClick: () => void;
  handlePageHeightChange:  (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMarginRightChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePageWidthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMarginLeftChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMarginChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMarginTopChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  margin: string;
  marginTop: string;
  marginLeft: string;
  marginRight: string;
  pageHeight: string;
  pageWidth: string;
}

const TopComponentPDF : React.FC<TopComponentPropsPDF> = ({margin, marginTop, marginLeft, marginRight, pageHeight, pageWidth, onButtonClick, handlePageHeightChange, handleMarginRightChange, handlePageWidthChange, handleMarginLeftChange, handleMarginChange, handleMarginTopChange}) => {
  return (
    <div className="top-component">
      <button onClick={onButtonClick} className='print-button'><KeyboardReturnTwoTone  /></button>
      <ExpandableTextInputGroup handlePageHeightChange={handlePageHeightChange} handleMarginRightChange={handleMarginRightChange} handlePageWidthChange={handlePageWidthChange}
      handleMarginLeftChange={handleMarginLeftChange} handleMarginChange={handleMarginChange} handleMarginTopChange={handleMarginTopChange} margin={margin} marginTop={marginTop} marginLeft={marginLeft} 
      marginRight={marginRight} pageHeight={pageHeight} pageWidth={pageWidth}
      />
    </div>
  );
};

export default TopComponentPDF;