import { useEffect, useState, useRef } from 'react';
import './App.css';
import PDFFile from './components/PDFFile';
import { useNavigate, useLocation } from 'react-router-dom';
import TopComponent from './components/TopComponent';
import { Tooltip } from 'react-tooltip';
import CircularProgress from '@mui/joy/CircularProgress';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Expand, Visibility, ZoomIn } from '@mui/icons-material';
import { OpenInFull } from '@mui/icons-material';


const circle = require('./photos/circle.png');
const circleWithBlueCheckmark = require('./photos/circleWithCheckmark.png');
const testFolder = '1h01VMoi230NKketOl2-7AHCyGmVrye3v';
const pics : any = [];
let selected : any[] = [];
let idArr : any[] = [];
let quantities : number[] = [];
let usedPageTokens: string[] = [];
// fetchPicturesFromFolder(testFolder).then((pictures : [any]) => {
//   pictures.forEach(pic => {
//     pics.push_back(pic);
//   });
// });

const outlineStyle = {
  border: '2px dashed #ccc',
  width: '200px',
  height: '200px',
  margin: '10px',
};

const windowHeight = window.innerHeight;
const ImageGallery = (images : [any]) => (
  <div>
    {images.map((image) => (
      <div key={image.id}>
        <img src={image.thumbnailLink} alt={image.name} />
      </div>
    ))}
  </div>
);
let nextPageToken = '';
export default function Home() {
  //const t: any[] = [];

  //const [backendData, setBackendData] = useState([]);
  
  const [images, setImages] : any[] = useState([]);
  const [pdfDisplay, setPdfDisplay] = useState(PDFFile([]));
  const [searchQuery, setSearchQuery] = useState('by');
  const [loadedImages, setLoadedImages] = useState(['']);
  const [clearSearchQuery, setClearSearchQuery] = useState(false);
  const [clearingPage, setClearingPage] = useState(false);
  // const [nextPageToken, setNextPageToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {state} = useLocation();
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [maxTranslate, setMaxTranslate] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const imageRef = useRef<HTMLImageElement>(null);

  const handleEnlarge = (src: string) => {
    setEnlargedImage(src);
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleClose = () => {
    setEnlargedImage(null);
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  };

  
  const handleZoomIn = (event: React.MouseEvent<HTMLImageElement>) => {
    if (!enlargedImage) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    if(zoomLevel == 1) {
      const newZoom = zoomLevel + 1;
      const imageCenterX = rect.width / 2;
      const imageCenterY = rect.height / 2;
      const newX = position.x - (offsetX - imageCenterX) * (newZoom - zoomLevel);
      const newY = position.y - (offsetY - imageCenterY) * (newZoom - zoomLevel);
  
      setZoomLevel(newZoom);
      setPosition({ x: newX, y: newY });
    } else {
      setZoomLevel(1);
      setPosition({ x: 0, y: 0 });
    }

    event.stopPropagation();
  };





  if(state) {
    selected = state['selected'];
    idArr = state['idArr'];
    quantities = state['quantities'];
  }

  
//   useEffect(() => {
//     if(state) {
//       //setImages(state['images'] ?? []);
//       //setLoadedImages(state['loaded'] ?? ['']);
//       //console.log(loadedImages);
//     }
// }, []);

  //const [selected, setSelected] = useState(t);

  const fetchImages = (query : any, pageToken = '') => {
    setIsLoading(true);
    if(pageToken != '' && usedPageTokens.includes(pageToken)){
      return;
    }
    usedPageTokens.push(pageToken);
    fetch(`/search/${query}?pageToken=${pageToken}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('fetching, Pagetoken = '  + pageToken);
        if(searchQuery != 'by') {
          setImages(data['pictures']);
        }else if(data['pictures']) { 
          if(clearingPage) {
            setImages((oldImages : any[]) => [...data['pictures']]);
            nextPageToken = data['nextPageToken'];
            setClearingPage(false);
          } else {
            setImages((oldImages : any[]) => [...oldImages, ...data['pictures']]);
            nextPageToken = data['nextPageToken'];
          }
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
        setIsLoading(false);
      });
  };


  const debouncedFetchImages = (() => {
    let timeout : any;
    return (query: string, pageToken = '') => {
      clearTimeout(timeout);
      setIsLoading(true);
      timeout = setTimeout(() => {
        fetchImages(query, pageToken);
      }, 100); //  milliseconds
    };
  })();

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
       document.documentElement.offsetHeight - 100 // Load more when the user is near the bottom
    ) {
      //console.log(nextPageToken);
      if (!isLoading && nextPageToken) {
        //console.log("fetch");
        setIsLoading(true);
        debouncedFetchImages(searchQuery, nextPageToken);
      
    }
  }
}

const handleScroll2 = (event: React.WheelEvent<HTMLDivElement>) => {
  event.stopPropagation();
  if (enlargedImage) {
    event.preventDefault(); // Prevent the default scroll behavior
    const deltaX = event.deltaX;
    const deltaY = event.deltaY;
    setPosition((prevPosition) => ({
      x: prevPosition.x - deltaX,
      y: Math.min(zoomLevel * 250, Math.max(zoomLevel * -250, prevPosition.y - deltaY)),
    }));
  }
};


useEffect(() => {
  // Update max translation based on image and viewport dimensions
  if (imageRef.current && enlargedImage) {
    const imageWidth = imageRef.current.width * zoomLevel;
    const imageHeight = imageRef.current.height * zoomLevel;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the scaled viewport dimensions based on the inverse of the zoom level
    const scaledViewportWidth = viewportWidth / zoomLevel;
    const scaledViewportHeight = viewportHeight / zoomLevel;

    // Calculate the maximum translation values based on the scaled viewport dimensions
    const maxX = Math.max(0, scaledViewportWidth - imageWidth);
    const maxY = Math.max(0, scaledViewportHeight - imageHeight);

    setMaxTranslate({ x: maxX, y: maxY });
  }
}, [zoomLevel, enlargedImage]);

useEffect(() => {
  // Prevent scrolling on the document when the overlay is open
  if (enlargedImage) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
}, [enlargedImage]);

  useEffect(() => {
    nextPageToken = '';
    setImages([]);
    setLoadedImages([]);
    usedPageTokens = [];
    fetchImages(searchQuery);
  }, [searchQuery]);
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const imageWrapper = imageWrapperRef.current;
  //   if (imageWrapper) {
  //     imageWrapper.style.transform = `translate(${position.x}px, ${position.y}px) scale(${zoomLevel})`;
  //   }
  // }, [position, zoomLevel]);

  // useEffect(() => {
  //   fetch(`/search/${searchQuery}?pageToken=`)
  //   .then((response) => response.json())
  //     .then((data) => {
  //       setImages(data.pictures);
  //       setNextPageToken(data.nextPageToken);
  
  // })}, [searchQuery]);

   function sanitize(input: string) {
    const map: Record<string, string> = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      '/': '&#x2F;',
    };
    const reg = /[&<>"'/]/gi;
  
    return input.replace(reg, (match) => map[match]);
  }
  // useEffect(() => {
  //   fetch("/images/1-e8p0ZRHJ-m2U1WCycrTsIhCTwQjhHm1")
  //   .then((response) => response.json())
  //     .then((data) => {
  //       //console.log(data);
  //       //setSelected([...selected, data])
    
  // })}, []);
  
  const imageClick = (id : string) => {
    
    if(idArr.includes(id)) {
        const index = idArr.indexOf(id);
        idArr.splice(index, 1);
        selected.splice(index, 1);
        quantities.splice(index, 1);
        hideBlueCheck(id);
        return;
    }

    showBlueCheck(id);
    
    fetch(`/images/${id}`)
    .then((response) => response.json())
      .then((data) => {
        idArr.push(id);
        selected.push(data);
        quantities.push(1);
        //setPdfDisplay(PDFFile(selected));
      });


    //links.push(shareableLink(id));
    
  }

  const handleImageLoad = (id : string) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, id]);
  };


  function shareableLink(id : string) {
    const urlPre = 'https://drive.google.com/uc?export=view&id=';
    return urlPre + id;
  }

  function thumbnailLink(id : string) {
    const urlPre = 'https://drive.google.com/thumbnail?id=';
    return urlPre + id + '&sz=s4000';
  }

  function mouseEnter (id : string) {
    const cir = document.getElementById(id + 'X');
    if(cir) {
      cir.style.opacity = '1.0';
    }
  }

  function mouseLeave (id : string) {
    const cir = document.getElementById(id + 'X');
    if(cir) {
      cir.style.opacity = '0.0';
    }
  }

  function showBlueCheck (id : string) {
    const cir = document.getElementById(id + 'Y');
    if(cir) {
      cir.style.opacity = '1.0';
    }
  }

  function hideBlueCheck (id : string) {
    const cir = document.getElementById(id + 'Y');
    if(cir) {
      cir.style.opacity = '0.0';
    }
  }

  const handleSearch = (query : string) => {
    if(query == '') {
    const cir = document.getElementById("back-arrow-button");
    if(cir) {
      cir.style.opacity = '0.0';
    }
      setSearchQuery('by');
    } else { 
      const cir = document.getElementById("back-arrow-button");
      if(cir) {
        cir.style.opacity = '1.0';
      }
      setSearchQuery(sanitize(query));
  }
  };
  
  function switchPage () {
    navigate('/pdfPage', { state: { selected: selected, idArr: idArr, quantities: quantities,/* images: images, loaded: loadedImages */} });
  }

  function switchToUploadPage () {
    navigate('/upload-spines', { state: { selected: selected, idArr: idArr, quantities: quantities} });
  }

  function backClick () {
    setClearSearchQuery(!clearSearchQuery);
    setImages([]);
    setLoadedImages([]);
    usedPageTokens = [];
    setClearingPage(true);
    setSearchQuery('by');
    const cir = document.getElementById("back-arrow-button");
    if(cir) {
      cir.style.opacity = '0.0';
    }
  }

  const handleTileClick = (gameName : string) => {
    setSearchQuery(sanitize(gameName));
    navigate('/');
    setLoadedImages([]);
    const cir = document.getElementById("back-arrow-button");
    if(cir) {
      cir.style.opacity = '1.0';
    }
  };

  useEffect(() => {
    const handlePopstate = () => {
      //setImages([]);
      setLoadedImages([]);
    };

    // Add the event listener for the popstate event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    return () => {
      setLoadedImages([]);
    };
  }, []);

  useEffect(() => {
    const cir = document.getElementById("circleLoader");
    if(cir) {
      if(isLoading) {
        cir.style.opacity = '1.0';
      }else{
        cir.style.opacity = '0.0';
      }
    }

  }, [isLoading])

  const getRidOfEndingTag = (name: string) => {
    const endingTags = ['.jpg', '.png', '.webp', '.jpeg'];
    let retName = name;
    endingTags.forEach(endingTag => {
      if(name.endsWith(endingTag)) {
        retName =  name.substring(0, name.length - endingTag.length);
      }
    });

    return retName;
  }

  // const loadMoreImages = (entries : any[]) => {
  //   if (entries[0]?.isIntersecting) {
  //     fetchSpines();
  //   }
  // };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(loadMoreImages, { rootMargin: '20px' });
  //   const target = document.querySelector('#observer');
  //   if(target) {
  //     observer.observe(target);
  //     return () => observer.unobserve(target);
  //   }
  // }, []);
 
  return (
    <div className="Home" onWheel={handleScroll2}>
    <TopComponent onSearch={handleSearch} onButtonClick={() => {switchPage()}} onBackClick={() => {backClick()}} onUploadClick={() => {switchToUploadPage()}} clearSearchQuery={clearSearchQuery}/>
    {/* <div id="topPaddingHomePage"></div> */}
    {  (typeof images === 'undefined') 
      ? (<p> Loading </p>) 
      : (images.map((image : any) => ( 
        <div className='imageContainer' data-tooltip-id={image['id']}>
           <button className="enlarge-button" onClick={() => handleEnlarge(image['id'])}>  <FullscreenIcon /></button>
          {!loadedImages.includes(image['id']) &&  <img width={0.41 * 110} height={6.35 * 100} className='spineImageLoading'></img>}
          <img alt="Image" id={image["id"]} key={image["id"]} width={0.41 * 110} height={6.35 * 100} className='spineImage' referrerPolicy="no-referrer" 
            src={thumbnailLink(image["id"])} //Janky fix idk what to do 
            //src={(shareableLink(image["id"]))} <- old version, ideally we do this
            onMouseEnter={()=> mouseEnter(image['id'])} 
            onMouseLeave={()=> mouseLeave(image['id'])}
            onClick={() => imageClick(image["id"])}
            onLoad={() => handleImageLoad(image["id"])}
            style={{opacity: loadedImages.includes(image['id']) ? 1 : 0, transition: 'opacity 0.75s ease-in-out'}}
            >  
          </img>
          {(idArr.includes(image['id'])) ? <img className={'blueCheckShown'} id={image["id"] + "Y"} key={image["id"] + "Y"} src={circleWithBlueCheckmark} width={30} height={30}></img> 
          : <img className={'blueCheck'} id={image["id"] + "Y"} key={image["id"] + "Y"} src={circleWithBlueCheckmark} width={30} height={30}></img>}
            
            <img className={'emptyCircle'} id={image["id"] + "X"} key={image["id"] + "X"} src={circle} width={30} height={30} ></img>
            <Tooltip className={'toolTip'} place="bottom" id={image["id"]}> {getRidOfEndingTag(image["name"])} </Tooltip>
        </div>
      )))}
      {enlargedImage && (
    <div className="overlay" onClick={handleClose} onWheel={handleScroll2}>

            <img
              src={thumbnailLink(enlargedImage)}
              alt="Enlarged"
              ref={imageRef}
              className="enlarged-image"
              onClick={handleZoomIn}
              style={{ transform: `translate(${position.x}px, ${position.y}px) scale(${zoomLevel})` }}
            />
          </div>

      )}
      <br></br>
      <div id="circleLoader">
        <CircularProgress size='md' />
      </div>
      <div id="observer" style={{ height: '100px' }}></div>
      
      {/* <img key={312321} width={0.41 * 100} height={6.35 * 100} className="spineImage" referrerPolicy="no-referrer" src={(shareableLink('1-e8p0ZRHJ-m2U1WCycrTsIhCTwQjhHm1'))} onClick={() => imageClick('1-e8p0ZRHJ-m2U1WCycrTsIhCTwQjhHm1')}></img> */}
      {/* <button onClick={() => setPdfDisplay(PDFFile(selected))}>Render PDF</button> */}
      <br></br>
      {/* <button onClick={() => {switchPage()}}>Next Page</button> */}
      {/* {pdfDisplay} */}
    </div>
  );
}

